<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img fluid src="@/assets/images/logo-horizontal.svg" alt="logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="background: #f5f9ff"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid src="@/assets/images/logo.png" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col
          v-if="!remember_me"
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary-light"
          >
            Benvenuto in AutoinCloud!
          </b-card-title>
          <b-card-text class="mb-2">
            Accedi ai servizi di AutoinCloud utilizzando le credenziali del tuo
            account.
          </b-card-text>

          <small v-if="login_error" class="text-danger">{{
            login_error
          }}</small>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="mario.rossi@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link
                    :to="null"
                    class="text-primary-light"
                    v-b-modal.modal-recovery
                  >
                    <small>Password Dimenticata?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Ricordami
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                :disabled="login_loading"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                <b-spinner v-if="login_loading" small></b-spinner>
                <span v-if="!login_loading">Accedi</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Copyright &copy; 2022 - Powered by </span>
            <b-link href="https://www.autoincloud.com" target="blank">
              <span>Autoincloud.com</span>
            </b-link>
          </b-card-text>

          <div class="divider my-2">
            <div class="divider-text">oppure</div>
          </div>

          <b-button variant="outline-secondary" block href="javascript:void(0)">
            <b-img
              class="mr-2"
              src="@/assets/images/google-g-logo.svg"
              alt="G"
            />
            <span class="text-dark font-weight-bolder"
              >Accedi tramite Google</span
            >
          </b-button>
        </b-col>

        <!-- Login Remember -->

        <b-col v-else sm="8" md="6" lg="12" class="px-xl-2 mx-auto text-center">
          <b-card-title class="mb-0 font-weight-bold" title-tag="h2">
            Bentornato
            <b class="text-primary">{{ user.user_name }}!</b>
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- submit buttons -->
              <b-button class="mb-2" variant="primary" @click="login"
                >Entra in AutoinCloud</b-button
              >
            </b-form>
          </validation-observer>

          <small class="text-center"
            >Non sei tu?
            <b-link :to="null" @click="deleteData">
              <small>Esci ed effettua l'accesso</small>
            </b-link></small
          >
        </b-col>
      </b-col>
    </b-row>

    <!-- Modal -->

    <b-modal
      id="modal-recovery"
      centered
      title="Password Smarrita?"
      cancel-title="Annulla"
      @ok.prevent="validationPasswordRecovery"
    >
      <validation-observer ref="recoveryMailValidation">
        <b-form-group label="Inserisci Indirizzo E-mail di recupero">
          <validation-provider
            #default="{ errors }"
            name="E-mail di recupero"
            rules="email|required"
          >
            <b-form-input v-model="recovery_email"> </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-recovery-success"
      centered
      title="Richiesta Completata"
      ok-only
      :ok-disabled="loading"
    >
      <p>
        Se l'indirizzo e-mail specificato dovesse corrispondere ad un account
        esistente, riceverai una mail
      </p>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { mapActions } from "vuex";
import { Requests } from "@/api";
import { getUserRole } from "@/libs/acl/ability";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BModal,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      user: {},
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      recovery_email: "",
      remember_me: null,
      // validation rulesimport store from '@/store/index'
      required,
      email,

      //Helpers

      login_error: null,
      login_loading: false,
      loading: false,
    };
  },

  created() {
    localize("it");

    this.userEmail = localStorage.getItem("user_mail");
    this.password = localStorage.getItem("user_secret");
    this.remember_me = localStorage.getItem("remember_me");

    this.getLoggedUser();
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({ setUser: "setUser" }),

    deleteData() {
      this.remember_me = null;
      this.userEmail = "";
      this.password = "";
      localStorage.removeItem("remember_me");
      localStorage.removeItem("user_mail");
      localStorage.removeItem("user_secret");
      localStorage.removeItem("user_data");
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },

    validationPasswordRecovery() {
      this.$refs.recoveryMailValidation.validate().then((success) => {
        if (success) {
          this.passwordRecovery();
          this.$bvModal.hide("modal-recovery");
          this.$bvModal.show("modal-recovery-success");
        }
      });
    },

    async passwordRecovery() {
      this.loading = true;

      let data = new FormData();

      data.append("user_email", this.recovery_email);

      try {
        await Requests.passwordRecovery(data);
        this.recovery_email = "";
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    getLoggedUser() {
      if (localStorage.getItem("remember_me")) {
        const user_data = JSON.parse(localStorage.getItem("user_data"));
        this.user = user_data;
      }
    },

    async getUserData() {
      try {
        const response = await Requests.getLoggedUser();

        switch (response.me.success) {
          case "true": {
            this.setUser(response.me.payload);
            localStorage.setItem("user", JSON.stringify(response.me.payload));
            return response.me.payload;
          }

          case "false": {
            console.debug(response.me.feedback);
          }
        }
      } catch (err) {
        console.debug(err);
      }
    },

    async login() {
      this.login_loading = true;

      let data = new FormData();

      data.append("user_email", this.userEmail);
      data.append("user_password", this.password);

      try {
        const response = await Requests.login(data);

        switch (response.login.success) {
          case "true": {
            this.$cookies.set(
              "access_token",
              response.login.token,
              response.login.exp
            );
            const user = await this.getUserData();
            this.$ability.update(getUserRole(user));
            if (this.status) {
              localStorage.setItem("remember_me", "true");
              localStorage.setItem("user_mail", this.userEmail);
              localStorage.setItem("user_secret", this.password);
              localStorage.setItem("user_data", JSON.stringify(user));
            }

            const workspace = localStorage.getItem("workspace");
            if (!workspace)
            {
              this.$router.push("/workspaces");  
            }
            else
            {
              this.$router.push("/dashboard");
            }
            break;
          }

          case "false": {
            this.login_error = response.login.feedback;
            break;
          }
        }
      } catch (err) {
        this.login_error = "Si è verificato un errore.";
      }
      this.login_loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
